import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Icon, Link, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { GiRunningShoe, GiHandBag } from "react-icons/gi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | Emblazin Cleaning - ваш лучший партнер по уборке
			</title>
			<meta name={"description"} content={"Принесем свежесть и блеск в ваше пространство\n"} />
			<meta property={"og:title"} content={"Главная | Emblazin Cleaning - ваш лучший партнер по уборке"} />
			<meta property={"og:description"} content={"Принесем свежесть и блеск в ваше пространство\n"} />
			<link rel={"shortcut icon"} href={"https://emblazingaurora.com/img/79761.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://emblazingaurora.com/img/79761.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://emblazingaurora.com/img/79761.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://emblazingaurora.com/img/79761.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://emblazingaurora.com/img/79761.png"} />
			<meta name={"msapplication-TileImage"} content={"https://emblazingaurora.com/img/1.jpg"} />
			
		</Helmet>
		<Components.Header />
		<Section
			padding="150px 0 150px 0"
			background="linear-gradient(180deg,#073120 0%,transparent 100%) 0 0 no-repeat,--color-darkL1 url(https://emblazingaurora.com/img/map.jpg) 0% 0% /cover repeat scroll padding-box"
			inner-width="90%"
			lazy-load
			sm-padding="80px 0 80px 0"
			quarkly-title="Team-16"
		>
			<Override slot="SectionContent" transition="color 0.2s ease 0s" />
			<Text
				margin="0px 80px 0px 80px"
				text-align="center"
				font="normal 500 64px/1.2 --fontFamily-sansHeavy"
				color="--light"
				lg-margin="0px 80px 0px 80px"
				md-margin="0px 0 0px 0"
				sm-font="normal 500 42px/1.2 --fontFamily-sansHeavy"
			>
				Emblazin Cleaning – ваш лучший партнер по уборке
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				margin="80px 0px 0px 0px"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				sm-grid-template-columns="1fr"
				sm-margin="50px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					height="500px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="0px 25px 50px 25px"
					background="linear-gradient(0deg,#233219 0%,transparent 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://emblazingaurora.com/img/1.jpg) 50% 0% /cover repeat scroll padding-box"
					hover-filter="brightness(50%)"
					sm-height="420px"
					sm-padding="0px 25px 30px 25px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					height="500px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="0px 25px 50px 25px"
					background="linear-gradient(0deg,#233219 0%,transparent 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://emblazingaurora.com/img/2.jpg) 50% 0% /cover repeat scroll padding-box"
					hover-filter="brightness(50%)"
					sm-height="420px"
					sm-padding="0px 25px 30px 25px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					height="500px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="0px 25px 50px 25px"
					background="linear-gradient(0deg,#233219 0%,transparent 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://emblazingaurora.com/img/3.jpg) 50% 0% /cover repeat scroll padding-box"
					hover-filter="brightness(50%)"
					sm-height="420px"
					sm-padding="0px 25px 30px 25px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					height="500px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="0px 25px 50px 25px"
					background="linear-gradient(0deg,#233219 0%,transparent 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://emblazingaurora.com/img/4.jpg) 50% 0% /cover repeat scroll padding-box"
					hover-filter="brightness(50%)"
					sm-height="420px"
					sm-padding="0px 25px 30px 25px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Добро пожаловать в Emblazin Cleaning
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					text-align="center"
					max-width="980px"
				>
					В компании Emblazin Cleaning мы стремимся предоставлять первоклассные клининговые услуги, которые сделают ваше помещение безупречным и привлекательным. Независимо от того, нужна ли вам уборка жилых, офисных или коммерческих помещений, наша профессиональная команда позаботится о том, чтобы каждая поверхность сверкала чистотой. Добро пожаловать в мир, где ваше окружение сияет чистотой.
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(2, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="gi"
						icon={GiRunningShoe}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					О компании Emblazin Cleaning
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Компания Emblazin Cleaning построена на фундаменте совершенства, надежности и заботы о клиентах. Наши квалифицированные специалисты используют передовое клининговое оборудование и экологически чистые продукты для достижения превосходных результатов. Мы понимаем, насколько важна чистая среда для вашего здоровья и продуктивности, и стремимся создавать помещения, которые способствуют вашему благополучию.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="gi"
						icon={GiHandBag}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Наша приверженность качеству
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						В компании Emblazin Cleaning мы гордимся своим тщательным подходом к уборке. Наша цель - превзойти ваши ожидания, предоставляя постоянный и высококачественный сервис. Мы стремимся поддерживать самые высокие стандарты во всем, что мы делаем, гарантируя, что ваше помещение будет не только чистым, но и более здоровым местом для жизни и работы.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Начните сегодня
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Откройте для себя отличие Emblazin Cleaning. Свяжитесь с нами прямо сейчас, чтобы запланировать свою первую уборку, и узнайте, почему многие выбирают нас для уборки. Чистое и свежее помещение - всего лишь звонок или электронное письмо.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Свяжитесь с нами
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://emblazingaurora.com/img/5.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});